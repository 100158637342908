// ./components/PopupProduct.jsx
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const PopupProduct = ({ productId, onClose }) => {

    const [currentPage, setCurrentPage] = useState('cater');

    

    const handleDelete = async () => {
        // await deleteProduct(productId);
        onClose();
    };

    const handleEditClick = async() => {
        setCurrentPage('EditCategory')
        // await deleteProduct(productId);
        // onClose();
    };

    const handleCopy = async() => {
        setCurrentPage('CopyCategory')
        
    }





    // Если данные получены успешно, отображаем информацию о товаре
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-8 rounded-lg w-full max-w-lg max-h-[80%] overflow-y-auto top-50">
                <div className="relative mb-4">
                    <div className="absolute top-0 right-0 m-0">
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-1 rounded focus:outline-none focus:shadow-outline"
                            onClick={onClose}
                        >
                            <i className='bx bx-x'></i>
                        </button>
                    </div>
                </div>
                <div className="p-4">
                    <h2 className="text-2xl mb-4">{productId}</h2>
                    <div className="mb-4">
                        <p className="block text-gray-700 text-sm mb-2">
                            <strong>ИНфо:</strong> {productId}
                        </p>
                    </div>
                    <div className="mb-4">
                        <p className="block text-gray-700 text-sm mb-2">
                            <strong>Инфо:</strong> {productId}
                        </p>
                    </div>


                    </div>
                </div>
            </div>

    );
};

PopupProduct.propTypes = {
    productId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default PopupProduct;
