// DashboardPage.js
import React, { useState } from 'react';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import DashboardCards from '../../components/DashboardCards';
import Users from '../Users/page';

const DashboardPage = () => {
    const [selectedComponent, setSelectedComponent] = useState('Главная');

    const handleMenuItemClick = (componentName) => {
        setSelectedComponent(componentName);
    };

    const pages = [
        { name: 'Главная', icon: 'bxs-dashboard', active: selectedComponent === 'Главная' },
        { name: 'Пользователи', icon: 'bx bxs-cog', active: selectedComponent === 'Пользователи' },
        { name: 'Рассылка', icon: 'bx bxs-cog', active: selectedComponent === 'Рассылка' },


        
    ];

    // Определение выбранного компонента
    let SelectedComponent;
    if (selectedComponent === 'Главная') {
        SelectedComponent = <DashboardCards />;
    }
     else if (selectedComponent === 'Пользователи') {
        SelectedComponent = <Users />;
    }

    return (
        <div>
            {/* Передача страниц и обработчика клика в компонент Sidebar */}
            <Sidebar pages={pages} isOpen={true} onItemClick={handleMenuItemClick} />
            <div className='content'>
                <Navbar />
                {/* Отображение выбранного компонента */}
                {SelectedComponent}
            </div>
        </div>
    );
};

export default DashboardPage;
