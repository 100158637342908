import React, { useState } from 'react';

const Navbar = () => {
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [darkTheme, setDarkTheme] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
        document.querySelector('.sidebar').classList.toggle('close');
    };

    const toggleTheme = () => {
        setDarkTheme(!darkTheme);
        document.body.classList.toggle('dark');
    };

    return (
        <div>
            <nav>
                <i className='bx bx-menu' onClick={toggleSidebar}></i>
                <form action="#">
                    <div className="form-input">
                        {/* <input type="search" placeholder="Search..." /> */}
                        {/* <button className="search-btn" type="submit"><i className='bx bx-search'></i></button> */}
                    </div>
                </form>
                <input type="checkbox" id="theme-toggle" hidden checked={darkTheme} onChange={toggleTheme} />
                <label htmlFor="theme-toggle" className="theme-toggle"></label>
                <a href="#" className="notif">
                    {/* <i className='bx bx-bell'></i> */}
                    {/* <span className="count">0</span> */}
                </a>
                <a href="#" className="profile">
                    {/* <img src="images/logo.png" alt="Logo" /> */}
                </a>
            </nav>
        </div>
    );
};

export default Navbar;
