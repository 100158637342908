import React, { useState, useEffect } from 'react';
import RowUser from './components/useRows'; 
import useGetUsers from './hooks/AddRows';
import PopupProduct from './components/popup_product';

const Users = () => {
    const [isPopupProductOpen, setIsPopupProductOpen] = useState(false);
    const [currentProductId, setCurrentProductId] = useState(null);
    const { users, reloadData } = useGetUsers();

    

    const [filters, setFilters] = useState({
        id: '',
        user_name: '',
    });


    const handlePopupProductOpen = (productId) => {
        console.log(`click ${productId}`)
        setCurrentProductId(productId);
        setIsPopupProductOpen(true);
        console.log(`Открыть попап: ${isPopupProductOpen}`);
        
    };

    const handlePopupProductClose = () => {
        setIsPopupProductOpen(false);
        setCurrentProductId(null);
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target; // Правильная деструктуризация 'name'
        setFilters({
            ...filters,
            [name]: value // Используем 'name', чтобы обновить правильное поле
        });
    };
    

    const filteredUsers = users.filter(user => 
        user.user_name && // Исключаем пользователей без имени
        (user.id.toString().includes(filters.id) || filters.id === '') &&
        ((user.user_name || '').toLowerCase().includes(filters.user_name.toLowerCase()) || filters.user_name === '')
    );
    const sortedUsers = filteredUsers.sort((a, b) => b.id - a.id);
    return (
        <main>
            <div className="header">
                <div className="left">
                    <h1>Пользователи</h1>
                </div>
            </div>
            <ul className="insights"></ul>
            <div className="bottom-data">
                <div className="orders">
                    <div className="header">
                        <i className='bx bx-receipt'></i>
                        <h3>Управление пользователями</h3>
                        <i className='bx bx-refresh' onClick={reloadData}></i>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>ID пользователя</th>
                                <th>Имя</th>
                            </tr>
                            <tr>
                                <th>
                                    <div className="filter">

                                    <input type="text" name="id" value={filters.id} onChange={handleFilterChange} placeholder="Фильтр по ID" />
                                    </div>
                                </th>
                                <th>
                                    <div className="filter">

                                    <input type="text" name="user_name" value={filters.user_name} onChange={handleFilterChange} placeholder="Фильтр по имени" />
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedUsers.map(user => (
                                <RowUser
                                    onClick={() => handlePopupProductOpen(user.id)}
                                    key={user.id}
                                    id={user.id}
                                    user_name={user.user_name}
                                />
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {isPopupProductOpen && (<PopupProduct  productId={currentProductId} onClose={handlePopupProductClose}/>)}
        </main>
    );
};

export default Users;
